import React, { useState } from 'react';

import DefaultLayout from '../../layouts';
import ContentSection from '../../layouts/content-section';
import Metadata from '../../components/metadata';

const MEMBERSHIP_CHECK_LOADING = 'MEMBERSHIP_CHECK_LOADING';
const MEMBERSHIP_CHECK_SUCCESSFUL = 'MEMBERSHIP_CHECK_SUCCESSFUL';
const MEMBERSHIP_CHECK_ERROR = 'MEMBERSHIP_CHECK_ERROR';

const CheckMembership = ({ location }) => {
  return (
    <DefaultLayout>
      <Metadata
        page={{
          title: 'Tarkista jäsenyys - NääsPeksi',
          excerpt: '',
        }}
        location={location}
      />

      <ContentSection>
        <h1>Tarkista jäsenyys</h1>
        <p>
          Jäsenyyden tarkistus on tällä hetkellä pois käytöstä. Korjaamme tilannetta.
        </p>
      </ContentSection>
    </DefaultLayout>
  );
};

export default CheckMembership;
